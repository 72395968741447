import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const connectorConfig = {
  s3: {
    displayName: "S3 (Deasie home bucket)",
    headers: [
      "display_name",
      "bucket_name",
      "base_path",
      "access_key_id",
      "secret_access_key",
    ],
    newConnector: () => ({
      storage: {
        name: "",
        type: "s3",
        credentials: { access_key_id: "", secret_access_key: "" },
      },
      base_path: "",
      name: "s3",
    }),
  },
  s3bucket: {
    displayName: "S3",
    headers: [
      "display_name",
      "bucket_name",
      "base_path",
      "access_key_id",
      "secret_access_key",
    ],
    newConnector: () => ({
      storage: {
        name: "",
        type: "s3bucket",
        credentials: { access_key_id: "", secret_access_key: "" },
      },
      base_path: "",
      name: "s3bucket",
    }),
  },
  azureblob: {
    displayName: "Azure Blob",
    headers: [
      "display_name",
      "storage_account",
      "storage_container",
      "tenant_id",
      "client_id",
      "client_secret",
    ],
    newConnector: () => ({
      storage: {
        name: "",
        type: "azureblob",
        credentials: { tenant_id: "", client_id: "", client_secret: "" },
      },
      base_path: "",
      name: "azure",
    }),
  },
  sharepoint: {
    displayName: "Sharepoint",
    headers: [
      "display_name",
      "site_name",
      "folder_path",
      "client_id",
      "client_secret",
      "sharepoint_url",
    ],
    newConnector: () => ({
      storage: {
        name: "",
        type: "sharepoint",
        credentials: { client_id: "", client_secret: "", sharepoint_url: "" },
      },
      base_path: "",
      name: "",
    }),
  },
  qdrant: {
    displayName: "Qdrant",
    headers: [
      "display_name",
      "collection_name",
      "url",
      "api_key",
      "file_name_key",
      "text_key",
    ],
    newConnector: () => ({
      storage: {
        name: "",
        type: "qdrant",
        credentials: { url: "", api_key: "" },
      },
      base_path: "",
      name: "",
      file_name_key: "",
      text_key: "",
    }),
  },
};

export function DataStoreTable({ dataSources, onDataChange }) {
  const [tableData, setTableData] = useState(dataSources);
  const [newTitles, setNewTitles] = useState(
    Object.fromEntries(Object.keys(connectorConfig).map((key) => [key, ""])),
  );

  const updateTableData = (header, key, connectorType, value) => {
    const newData = { ...tableData };
    const connector = newData[key];

    if (header === "display_name") return;

    if (["bucket_name", "storage_account", "site_name"].includes(header)) {
      connector.storage.name = value;
    } else if (
      [
        "storage_container",
        "folder_path",
        "collection_name",
        "base_path",
      ].includes(header)
    ) {
      connector.base_path = value;
      if (connectorType === "qdrant") connector.storage.name = value;
    } else if (["file_name_key", "text_key"].includes(header)) {
      connector.storage[header] = value;
    } else if (
      connector.storage.credentials &&
      header in connector.storage.credentials
    ) {
      connector.storage.credentials[header] = value;
    }

    console.log("newData", newData);
    setTableData(newData);
    onDataChange(newData);
  };

  const addRow = (connectorType) => {
    if (newTitles[connectorType]) {
      const newData = {
        ...tableData,
        [newTitles[connectorType]]: {
          ...connectorConfig[connectorType].newConnector(),
          name: newTitles[connectorType],
        },
      };
      setTableData(newData);
      setNewTitles({ ...newTitles, [connectorType]: "" });
      onDataChange(newData);
    }
  };

  const deleteRow = (key) => {
    const { [key]: _, ...newData } = tableData;
    setTableData(newData);
    onDataChange(newData);
  };

  const getDisplayValue = (value, header) => {
    if (["bucket_name", "storage_account", "site_name"].includes(header)) {
      return value.storage.name;
    } else if (
      [
        "storage_container",
        "folder_path",
        "collection_name",
        "base_path",
      ].includes(header)
    ) {
      return value.base_path;
    } else if (["file_name_key", "text_key"].includes(header)) {
      return value.storage[header];
    } else if (
      value.storage.credentials &&
      header in value.storage.credentials
    ) {
      return value.storage.credentials[header];
    }
    return "";
  };

  const renderConnectorTable = (connectorType, config) => (
    <div
      key={connectorType}
      className="mb-8 bg-white rounded-xl shadow-lg border-2 border-gray-200 transition-colors duration-300"
    >
      <h3 className="text-2xl font-bold text-gray-800 p-6 border-b-2 border-gray-200">
        {config.displayName}
      </h3>
      <div className="p-6">
        <table className="w-full">
          <thead>
            <tr>
              {config.headers.map((header) => (
                <th
                  key={header}
                  className="py-3 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
              <th className="w-10"></th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(tableData)
              .filter(([_, value]) => value.storage.type === connectorType)
              .map(([key, value]) => (
                <tr key={key} className="border-t border-gray-200">
                  {config.headers.map((header) => (
                    <td key={header} className="py-4">
                      <input
                        type="text"
                        className={`w-full px-3 py-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition duration-200 ease-in-out ${header === "display_name" ? "bg-gray-100 cursor-not-allowed" : ""}`}
                        value={
                          header === "display_name"
                            ? key
                            : getDisplayValue(value, header)
                        }
                        onChange={(e) =>
                          updateTableData(
                            header,
                            key,
                            connectorType,
                            e.target.value,
                          )
                        }
                        readOnly={header === "display_name"}
                      />
                    </td>
                  ))}
                  <td className="py-4">
                    <button
                      className="text-gray-500 hover:text-red-600 transition-colors duration-200"
                      onClick={() => deleteRow(key)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="mt-6 flex items-center">
          <input
            className="flex-grow px-4 py-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition duration-200 ease-in-out mr-4"
            placeholder={`Enter name for new ${config.displayName} connection`}
            value={newTitles[connectorType]}
            onChange={(e) =>
              setNewTitles({ ...newTitles, [connectorType]: e.target.value })
            }
          />
          <button
            className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => addRow(connectorType)}
            disabled={!newTitles[connectorType]}
          >
            Add Connection
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {Object.entries(connectorConfig).map(([connectorType, config]) =>
        renderConnectorTable(connectorType, config),
      )}
    </>
  );
}

export default DataStoreTable;
